<template>
    <div class="premium-survey-start">
        <StackRouterHeaderBar :left-button="'close'" />
        <span class="title f-bold f-32" v-html="$translate('PREMIUM_SURVEY_START_TITLE')" />
        <span class="desc f-16" v-html="$translate('PREMIUM_SURVEY_START_DESC')" />
        <img src="../../assets/images/premium/premium-text.png" />

        <div class="button-wrapper w-100">
            <button class="btn btn-primary f-15 w-100" @click="next" v-html="$translate('START')" />
        </div>
    </div>
</template>
<script>
import premiumSurveyQuestionService from '@/services/premium-survey'

export default {
    name: 'PremiumSurveyStartPage',
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#0E0B54',
                bottom: '#262289',
            },
        })
        this.init()
    },
    beforeDestroy() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#fff',
        })
    },
    methods: {
        async init() {
            const data = await premiumSurveyQuestionService.myAnswer()
            if (!data.ret) {
                this.$toast.error(data.msg)
                this.$stackRouter.clear()
            }
        },
        next() {
            return this.$stackRouter.push({
                name: 'PremiumSurveyQuestionPage',
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.stack-router-header-bar {
    background-color: #0e0b54;

    ::v-deep .left-btn > .material-icons {
        color: $white;
    }
}
.premium-survey-start {
    background-image: linear-gradient(to top, #262289, #0e0b54 50%);
    height: 100vh;
    @include flex;
    @include items-center;

    .title,
    .desc {
        color: $yellow-premium;
        text-align: center;
        line-height: normal;
    }

    .title {
        margin-top: 48px;
        letter-spacing: -0.4px;
    }

    .desc {
        margin-top: 80px;
        letter-spacing: -0.2px;
    }

    img {
        position: fixed;
        top: 115.1px;
        padding: 0 15.8px 0 24.2px;
    }

    .button-wrapper {
        padding: 16px 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        position: fixed;
        bottom: 0;

        .btn {
            height: 48px;
            color: #0e0b54;
            background-image: linear-gradient(99deg, #edbf5e 20%, #e9b04e 60%, #f3d27a);
            @include f-medium;
        }
    }
}
</style>
